import React, { useEffect, useState } from 'react';
import RepositoryManager from './RepositoryManager';
import TaskManager from './TaskManager';
import PRManager from './PRManager';
import PRButtons from './PRButtons'; // Import PRButtons
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';

const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return process.env.REACT_APP_API_BASE_URL;
    case 'development':
    default:
      return 'http://localhost:5000';
  }
};

axios.defaults.baseURL = getBaseUrl();

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedRepo, setSelectedRepo] = React.useState('');
  const [jiraToken, setJiraToken] = useState(''); // State to store Jira token
  const [taskManagerEnabled, setTaskManagerEnabled] = useState(false);
  const [prManagerEnabled, setPrManagerEnabled] = useState(false); // Initial state shows PRManager


  // Fetch token from localStorage or extract from URL on mount
  useEffect(() => {
    // Vérification des tokens à chaque chargement
    const accessToken = localStorage.getItem('access_token');
    const githubToken = localStorage.getItem('github_token');
    
    if (!accessToken || !githubToken) {
        navigate('/login', { replace: true });
    }
  }, [navigate]);

  // Configuration axios
  useEffect(() => {
      axios.interceptors.request.use(config => {
          config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
          return config;
      });
  }, []);

  // Function to enable TaskManager and disable PRManager
  const handleTaskManagerEnable = () => {
    setTaskManagerEnabled(true);
    setPrManagerEnabled(false); // Hide PRManager
  };

  // Function to enable PRManager and disable TaskManager
  const handlePrManagerEnable = () => {
    setTaskManagerEnabled(false);
    setPrManagerEnabled(true); // Show PRManager
  };

  // Handle Jira login
  const handleJiraLogin = () => {
    window.location.href = `${getBaseUrl()}/api/auth/jira`;
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-title">
        <h1>Dashboard</h1>
      </div>
      {/* Show Jira login button if not logged into Jira */}
      {/* {!jiraToken ? (
        <div>
          <p>You are logged in with GitHub. Would you like to connect to Jira?</p>
          <button onClick={handleJiraLogin}>Login with Jira</button>
        </div>
      ) : (
        <p>You are connected to Jira!</p>
      )} */}

      <RepositoryManager onSelectRepo={setSelectedRepo} />
  
      {selectedRepo && (
        <>
          {/* Render PRButtons to toggle between TaskManager and PRManager */}
          <PRButtons
            showTaskManager={handleTaskManagerEnable}
            showPRManager={handlePrManagerEnable}
          />
  
          {prManagerEnabled && <PRManager selectedRepo={selectedRepo} />}
          {taskManagerEnabled && <TaskManager selectedRepo={selectedRepo} />}
        </>
      )}
    </div>
  );
};

export default Dashboard;
