import React, { useState, useEffect } from 'react';
import api from '../api';
import './RepositoryManager.css';

const RepositoryManager = ({ onSelectRepo }) => { // Retirer le token des props
    const [repositories, setRepositories] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        let isMounted = true;
        const fetchRepositories = async () => {
            try {
                setLoading(true);
                // L'intercepteur axios ajoute déjà le header Authorization
                const response = await api.get('/repositories');
                if (isMounted) {
                    setRepositories(response.data);
                    setError(null);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Fetch error:', error);
                    const status = error.response?.status || 503;
                    const message = status === 401 ? 'Authentication expired' : 
                                  status === 403 ? 'Permission denied' :
                                  'Server connection failed';
                    
                    setError(`${message} - Retrying... (${retryCount + 1}/3)`);
                    
                    if (retryCount < 3) {
                        setTimeout(() => {
                            setRetryCount(c => c + 1);
                            fetchRepositories();
                        }, 5000);
                    }
                }
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchRepositories(); // Plus besoin de vérifier le token

        return () => { isMounted = false; };
    }, [retryCount]); // Retirer le token des dépendances

    const handleRepoChange = (e) => {
        const repo = e.target.value;
        setSelectedRepo(repo);
        onSelectRepo(repo);
    };

    if (loading) {
        return (
            <div className="repo-status">
                <div className="loading-spinner"></div>
                <p>Loading repositories...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="repo-status error">
                <p>{error}</p>
                {retryCount >= 3 && (
                    <button 
                        className="retry-button"
                        onClick={() => setRetryCount(0)}
                    >
                        Retry Now
                    </button>
                )}
            </div>
        );
    }

    return (
        <div className="repo-container">
            <h2 className="repo-heading">Select a Repository</h2>
            <div className="repo-select-box">
                <select 
                    className="repo-select"
                    onChange={handleRepoChange} 
                    value={selectedRepo}
                    disabled={repositories.length === 0}
                >
                    <option value="">{repositories.length ? "Select Repository" : "No repositories found"}</option>
                    {repositories.map((repo) => (
                        <option key={repo} value={repo}>
                            {repo}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default RepositoryManager;