import React, { useState } from 'react';
import axios from 'axios';
import './UpdateExistentPR.css'; // Import CSS for styling
import Dialog from './Dialog'; // Import the Dialog component
import api from '../api'; // Import the API utility

// Helper function to format markdown-like text
const formatContent = (text) => {
    return text.split('\n').map((line, index) => {
      let formattedLine = line;
      
      // Format bold text
      formattedLine = formattedLine.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      
      // Format code blocks
      formattedLine = formattedLine.replace(/`(.*?)`/g, '<code>$1</code>');
      
      // Format lists
      if (formattedLine.startsWith('- ')) {
        return <li key={index} dangerouslySetInnerHTML={{ __html: formattedLine.slice(2) }} />;
      }
      
      return <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />;
    });
  };

const UpdateExistentPR = ({ existingPRs, selectedRepo }) => {
    const [selectedPR, setSelectedPR] = useState(null);
    const [combinedSuggestions, setCombinedSuggestions] = useState([]);
    const [taskDescription, setTaskDescription] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [loading, setLoading] = useState(false); // State for loading
    const [dialogVisible, setDialogVisible] = useState(false); // State for dialog visibility
    const [prUrl, setPrUrl] = useState(''); // State to hold PR URL
    const [version, setVersion] = useState('v1'); // State to track selected version
    const [suggestionsGenerated, setSuggestionsGenerated] = useState(false);
    const [taskPromptGenerated, setTaskPromptGenerated] = useState(false);
    const [includeTaskDescription, setIncludeTaskDescription] = useState(true);

    const handlePRSelection = async (prNumber) => {
        setSelectedPR(prNumber);
        if (!prNumber) return;

        try {
            setSuggestionsGenerated(false);
            setTaskPromptGenerated(false);
            
            const { data } = await api.post(
                '/pr/suggestions',
                { repo_name: selectedRepo, pr_number: prNumber }
            );
            
            setCombinedSuggestions(data.suggestions || []);
            setTaskDescription(data.task_prompt || '');
            setSuggestionsGenerated(data.suggestions?.length > 0);
            setTaskPromptGenerated(!!data.task_prompt);
        } catch (error) {
            alert(`Failed to fetch PR suggestions: ${error.response?.data?.msg || error.message}`);
        }
    };


    const handleTaskSubmit = async () => {
        setLoading(true);
        try {
            const endpoint = version === 'v1' ? '/tasks/update-pr/v1' : '/tasks/update-pr/v2';
            
            // Construire le contenu de la tâche conditionnellement
            let taskContent = '';
            if (includeTaskDescription && taskDescription) {
                taskContent += taskDescription;
            }
            if (additionalInfo) {
                if (taskContent) taskContent += '\n\n';
                taskContent += `Additional Information: ${additionalInfo}`;
            }
    
            const { data } = await api.post(
                endpoint,
                {
                    repo_name: selectedRepo,
                    pr_number: selectedPR,
                    task: taskContent || 'No task provided', // Fallback si vide
                }
            );
    
            setPrUrl(data.pr_url);
            setDialogVisible(true);
        } catch (error) {
            alert(`Failed to update pull request: ${error.response?.data?.msg || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = (visit) => {
        if (visit && prUrl) {
            window.open(prUrl, '_blank'); // Open PR URL in new tab
        }
        setDialogVisible(false); // Close dialog
    };

    return (
        <div className="update-pr-container">
            <h2 className="update-pr-heading">Update Existing Pull Request</h2>
            
            {/* Version selection */}
            <div className="version-selection">
                <label>
                    <input 
                        type="radio" 
                        value="v1" 
                        checked={version === 'v1'} 
                        onChange={() => setVersion('v1')} 
                    />
                    Best for simple projects
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="v2" 
                        checked={version === 'v2'} 
                        onChange={() => setVersion('v2')} 
                    />
                    Best for complex projects
                </label>
            </div>

            <div className="form-group">
                <label className="form-label">Select an Existing PR</label>
                <select
                    className="form-select"
                    onChange={(e) => handlePRSelection(e.target.value)}
                    value={selectedPR || ''}
                >
                    <option value="">Select PR</option>
                    {existingPRs.map((pr) => (
                        <option key={pr.number} value={pr.number}>
                            {pr.title}
                        </option>
                    ))}
                </select>
            </div>

            {selectedPR && (
                <>
                    <div className="section-header">
                        <h3>PR Suggestions</h3>
                        <span className={`status-badge ${suggestionsGenerated ? 'generated' : 'pending'}`}>
                            {suggestionsGenerated ? 'Generated' : 'Pending...'}
                        </span>
                    </div>
                    
                    {combinedSuggestions.length > 0 ? (
                        <div className="formatted-content">
                            {combinedSuggestions.map((suggestion, index) => (
                                <div key={index} className="suggestion-item">
                                    <div className="suggestion-header">
                                        <span className="file-info">
                                            {suggestion.file} (Line {suggestion.line})
                                        </span>
                                    </div>
                                    <div className="suggestion-content">
                                        {formatContent(suggestion.comment)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="no-content">No suggestions generated yet</p>
                    )}

                    <div className="section-header">
                        <h3>Task Prompt</h3>
                        <span className={`status-badge ${taskPromptGenerated ? 'generated' : 'pending'}`}>
                            {taskPromptGenerated ? 'Generated' : 'Pending...'}
                        </span>
                    </div>
                    
                    <div className="formatted-content task-prompt">
                        {taskDescription ? (
                            formatContent(taskDescription)
                        ) : (
                            <p className="no-content">Task prompt not generated yet</p>
                        )}
                    </div>

                    <textarea
                        className="task-textarea"
                        placeholder="Additional Information"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        style={{ resize: 'vertical', minHeight: '100px' }} // Allow vertical resize
                    />

                    <div className="task-options">
                        <label className="toggle-label">
                            <input
                                type="checkbox"
                                checked={includeTaskDescription}
                                onChange={(e) => setIncludeTaskDescription(e.target.checked)}
                            />
                            Include Task Description in Submission
                        </label>
                    </div>

                    {!loading ? (
                        <button className="submit-btn" onClick={handleTaskSubmit} disabled={(!includeTaskDescription && !additionalInfo) || (includeTaskDescription && !taskDescription)}>
                            Submit Task
                        </button>
                    ) : (
                        <button className="loading-btn" disabled>
                            Loading...
                        </button>
                    )}
                </>
            )}

            {dialogVisible && (
                <Dialog 
                    title="PR Update Successful" 
                    message="Do you want to visit the pull request?" 
                    onClose={handleDialogClose} 
                />
            )}
        </div>
    );
};

export default UpdateExistentPR;
