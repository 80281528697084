import React from 'react';
import './LandingPage.css'; // Import the CSS for styling

const LandingPage = () => {
  return (
    <div>
      <header className="header">
        <div className="logo">DEVER</div>
        <nav className="nav-links">
          <a href="#features">Features</a>
          <a href="#solutions">Solutions</a>
          <a href="/pricing">Pricing</a>
          <a href="/auth">Log In</a>
        </nav>
      </header>

      <section className="hero">
        <div className="hero-text">
          <h1>Your AI Developer</h1>
          <p>
          DEVER automates coding, creating, updating and reviewing GitHub Pull Requests, enabling you to build and improve your codebase without any coding skills. 
          <br></br>
          Save time and money with your AI Developer.
          </p>
          <button className="cta-button" onClick={() => (window.location.href = '/auth')}>
            Try it for Free
          </button>
        </div>
        <div className="hero-image">
          <img
            src="LOGO DEVER.png"
            alt="AI Developer Screenshot"
          />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;