import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
});

// Function to check if the token is a valid JWT and if it is expired
const isTokenExpired = (token) => {
  if (!token || typeof token !== 'string' || token.split('.').length !== 3) {
    console.warn('Invalid token format, removing it.');
    return true; // Treat invalid tokens as expired
  }

  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    return payload.exp * 1000 < Date.now(); // Check if token is expired
  } catch (e) {
    console.warn('Error decoding token, removing it.');
    return true; // If decoding fails, consider it expired
  }
};

api.interceptors.request.use(config => {
  let token = localStorage.getItem('access_token');

  // Check if the token is 'undefined' (string) or actually undefined, and remove it.
  if (token === undefined || token === 'undefined') {
    console.warn('Access token is undefined, removing it from localStorage.');
    localStorage.removeItem('access_token');
    token = null;
  }

  if (token) {
    if (isTokenExpired(token)) {
      console.warn('Access token is expired or invalid, removing it.');
      localStorage.removeItem('access_token');
      delete config.headers.Authorization;
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } else {
    delete config.headers.Authorization; // Remove header if no token
  }

  return config;
});

// Interceptor for handling responses and redirections
api.interceptors.response.use(
  response => {
    // Handle redirection in response (e.g., Stripe)
    if (response.data?.redirect_url) {
      window.location.href = response.data.redirect_url;
      console.log('Redirecting to:', response.data.redirect_url);
    }
    return response;
  },
  error => {
    if (error.response) {
      // Handle 401 errors (session expired)
      if (error.response.status === 401) {
        console.warn('Session expired. Redirecting to login.');
        localStorage.removeItem('access_token');
        localStorage.removeItem('github_token');
        window.location.href = '/login?session_expired=true';
      }
    }
    return Promise.reject(error);
  }
);

export default api;
