import React from 'react';

const PricingPage = () => {
    // Récupérer les variables d'environnement
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    const portalUrl = process.env.REACT_APP_STRIPE_PORTAL_URL;

    // Fonction pour ouvrir le portail client Stripe
    const handleOpenCustomerPortal = () => {
        window.location.href = portalUrl;
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Choose Your Subscription</h1>
            
            {/* Stripe Pricing Table */}
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id="user_12345" // Remplace avec l'ID utilisateur dynamique
            >
            </stripe-pricing-table>

            {/* Button to open Stripe Customer Portal */}
            <button
                onClick={handleOpenCustomerPortal}
                style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                }}
            >
                Manage My Subscription
            </button>
        </div>
    );
};

export default PricingPage;
