import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthInterceptor = ({ children }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('github_token');
                    navigate('/auth');
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [navigate]);

    return <>{children}</>;
};

export default AuthInterceptor;