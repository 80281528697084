import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthInterceptor from './components/AuthInterceptor';
import LandingPage from './components/LandingPage';
import AuthPage from './components/AuthPage';
import Login from './components/Login';
import PricingPage from './components/PricingPage';
import Dashboard from './components/Dashboard';

const App = () => {
  return (
      <Router>
          <AuthInterceptor>
              <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/dashboard/*" element={<Dashboard />} />
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>
          </AuthInterceptor>
      </Router>
  );
};

export default App;