import React, { useEffect, useState } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkTokens = () => {

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('session_expired')) {
                alert('Votre session a expiré, veuillez vous reconnecter');
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            const accessToken = localStorage.getItem('access_token');
            const githubToken = localStorage.getItem('github_token');
            
            // Si les deux tokens sont présents
            if (accessToken && githubToken) {
                navigate('/dashboard', { replace: true });
            }
            // Si aucun token, rediriger vers auth
            else if (!accessToken) {
                navigate('/auth', { replace: true });
            }
        };

        // Vérifier les paramètres d'URL en premier
        const urlParams = new URLSearchParams(location.search);
        const githubToken = urlParams.get('github_token');
        
        if (githubToken) {
            localStorage.setItem('github_token', githubToken);
            window.history.replaceState({}, document.title, window.location.pathname);
        }

        checkTokens();
        
        // Vérifier périodiquement l'état des tokens
        const interval = setInterval(checkTokens, 1000);
        return () => clearInterval(interval);
    }, [navigate, location]);

    const handleGitHubLogin = () => {
        window.location.href = `${API_BASE_URL}/api/auth/github?access_token=${localStorage.getItem('access_token')}`;
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Your_AI_Developer</h1>
            <div style={styles.loginBox}>
                <button style={styles.githubButton} onClick={handleGitHubLogin}>
                    Login with GitHub
                </button>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '300px',
    },
    githubButton: {
        padding: '10px 20px',
        fontSize: '1.1rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    rememberMeBox: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        marginLeft: '10px',
    },
};

export default Login;
